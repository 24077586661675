import Vue from "vue";
import Router from "vue-router";
import Mint from './components/Mint.vue';
import MintSale from './components/MintSale.vue';
// import LandingVue from './components/Landing.vue';
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    // {
    //   path: "*",
    //   name: "LandingVue",
    //   component: LandingVue,
    // },
    {
      path: "*",
      name: "Mint",
      component: Mint,
    },
    {
      path: "/privatesale",

      name: "Mint",
      component: Mint,
    },
    {
      path: "/publicsale",

      name: "MintSale",
      component: MintSale,
    }
  ],
});
