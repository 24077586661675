<template>
  <div class="pricing-container">
    <div class="heading">
      <div class="metamask">
        <img src="/metamask.png" />
        <h2>Metamask</h2>
      </div>
      <h1 class="glitch">
        <span class="glitch-title"> Public Sale is now live </span>
      </h1>
      <h4>MetaVogue is the first NFT collection to bring 3333 unique luxury fashion babes in the Metaverse.</h4>
    </div>
    <div class="sale-card">
      <div class="qty need-border">
        <p class="label">Quantity</p>
        <div class="controller">
          <div class="minus">
            <img src="/icons/minus.png" @click="nftQty > 1 && nftQty--" />
          </div>
          <p class="unit">{{ nftQty }}</p>
          <div class="plus">
            <img src="/icons/plus.png" @click="nftQty < 10 && isActive && !isPresaleActive && nftQty++ || nftQty < 2 && nftQty++" />
          </div>
        </div>
      </div>
      <div class="price unit-price need-border">
        <p>Unit Price</p>
        <div class="cost">
          <img src="/icons/eth.png" />
          <span>{{ unitPrice.toFixed(4) }}&nbsp;ETH</span>
        </div>
      </div>
      <div class="price total-price need-border">
        <p>Total Price</p>
        <div class="cost">
          <img src="/icons/eth.png" />
          <span>{{ (unitPrice * nftQty).toFixed(4) }}&nbsp;ETH</span>
        </div>
      </div>
      <div class="remaining">
        <p>Minted</p>
        <span>&nbsp;:&nbsp;</span>
        <p>{{ currentSupply }}/{{ totalTokens }}</p>
      </div>
    </div>
    <div class="cta">
      <a href="#" class="button primary invert wallet-cta" @click="connectWallet"> {{ accountID === "" ? "Connect wallet" : accountID.substring(1, 9) + "..." + accountID.substring(accountID.length - 6) }}</a>
      <a href="#" class="button primary invert mint-cta" @click="mint">{{ isMinting ? "Waiting..." : "Mint" }}</a>
    </div>
  </div>
</template>


<script setup>
var Web3 = require("web3");
var CryptoJS = require("crypto-js");
import abis from "../abis/Metavogue.json";
import address from "../address/address.json";
var MerkleTree = require("merkletreejs").MerkleTree;
var SHA256 = CryptoJS.SHA256;
const mainSite = 'https://metavogue.netlify.app/';
const leaves = address.addresses.map((x) => x.replace("0x", "0x000000000000000000000000"));
const tree = new MerkleTree(leaves, SHA256, { sortPairs: true });
const root = tree.getRoot().toString("hex");

export default {
  name: "Pricing",
  props: {
    totalNftCount: Number,
    remainingNftCount: Number
  },
  data() {
    return {
      nftQty: 2,
      unitPrice: 0.19,
      address: "",
      accountID: "",
      accountBalance: 0,
      abi: [],
      contract: [],
      wlClaimed: 0,
      // Contract
      isActive: false,
      isPresaleActive: false,
      currentSupply: 152,
      totalTokens: 3333,
      maxSupply: 3333,
      buyLimit: 2,
      nftPrice: 190000000000000000,
      whiteListMaxMint: 2,
      notAllowed: false,
      // Form data
      minted: false,
      isMinting: false,
    };
  },
  mounted() {
    const homeVid = document.querySelector('#mint-vid')
    if (homeVid) {
      homeVid.addEventListener('click', () => (window.location.href = mainSite))
    }
    document.querySelector('body')?.addEventListener('touchstart', () => {
      const videos = document.querySelectorAll('video')
      if (videos && videos.length) {
        videos.forEach(vid => {
          if (!vid.playing) {
            vid.play()
          }
        })
      }
    })
  },
   async created() {
    await this.loadWeb3();
    this.GetRoot();
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    GetMerkleProof(walletAddress) {
      const leaf = walletAddress;
      return tree.getHexProof(leaf.replace("0x", "0x000000000000000000000000"));
    },
    GetRoot() {
      console.log("root");
      console.log(root);
      console.log(address.addresses.length);
      console.log("contract : " + this.address);
      return `0x${root}`;
    },
    async loadWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async (accounts) => {
          await this.setWallet(accounts[0]);
          this.wlClaimed = await this.contract.methods.whiteListClaimed(this.accountID).call();
        });
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        window.alert("Non-Ethereum browser detected. You should consider trying MetaMask !");
      }

      await this.loadContractData();
      setInterval(
        function () {
          this.loadContractData();
        }.bind(this),
        1000
      );
    },
    async pick(nftsCountToMint) {
      this.nftsCountToMint = nftsCountToMint;
      console.log(this.nftsCountToMint);
    },
    async loadContractData() {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();

      if (networkId !== abis.network) {
        window.alert("Please change to ethereum mainnet.");
        return;
      }

      this.abi = abis.abi;
      this.address = abis.address;
      this.contract = new web3.eth.Contract(this.abi, this.address);
      this.nftPrice = await this.contract.methods.NFTPrice().call();
      this.unitPrice = this.nftPrice /"1000000000000000000"; 
      this.totalTokens = parseInt(await this.contract.methods.MAX_NFT_PUBLIC().call())+150;
      this.buyLimit = await this.contract.methods.BUY_LIMIT_PER_TX().call();
      this.isActive = await this.contract.methods.isActive().call();
      this.currentSupply = parseInt(await this.contract.methods.totalSupply().call())+150;
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
    },
    async setWallet(address) {
      this.accountID = address;
      this.accountBalance = await window.web3.eth.getBalance(this.accountID);
    },
    async connectWallet() {
      console.log("Connect to wallet");
      const web3js = window.web3;
      if (typeof web3js !== "undefined") {
        this.web3 = new Web3(web3js.currentProvider);
        const accounts = await window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .catch((err) => {
            alert(err.message);
          });
        await this.setWallet(accounts[0]);
        console.log("wlClaimed " + this.wlClaimed);
      } else {
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
        alert("Unable to connect to Metamask");
      }
    },
    //Minting Functionality
    async mint(e) {
      this.isMinting = true;
      e.preventDefault();
    
      if (this.accountID === "") {
        window.alert("Please connect wallet first!");
        this.isMinting = false;
        return;
      } 
      this.accountBalance = await window.web3.eth.getBalance(this.accountID);
      if (this.accountBalance <= this.nftPrice * this.nftQty) {
        this.isMinting = false;
        alert(`Insufficient funds`);
        return;
      }
       
      this.isActive = await this.contract.methods.isActive().call();
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
      console.log("isActive : ", this.isActive);
      console.log("isPresaleActive : ", this.isPresaleActive);

      if (!this.isActive) {
        this.isMinting = false;
        alert("Sale is not active yet!");
        return;
      }

      const noOfTokens = this.nftQty;
      console.log("nftPrice : ", this.nftPrice);
      if (this.isPresaleActive == true) {
        this.whiteListMaxMint = await this.contract.methods.WHITELIST_MAX_MINT().call();
        this.wlClaimed = parseInt(await this.contract.methods.whiteListClaimed(this.accountID).call());

        if (this.wlClaimed + this.nftQty > this.whiteListMaxMint) {
          alert(`Already minted ${this.wlClaimed} but max is ${this.whiteListMaxMint}`);
          this.isMinting = false;
          //this.notAllowed = true;
          return;
        }

        console.log("whiteListMaxMint : ", this.whiteListMaxMint);
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (noOfTokens > this.whiteListMaxMint) {
          alert("Buy limit for presale is : " + this.whiteListMaxMint);
          this.notAllowed = true;
        } else if (this.totalSupply >= this.totalTokens) {
          alert("Sold out!");
        } else {
          const proof = await this.GetMerkleProof(this.accountID);
          if (proof.length == 0) {
            //alert("This wallet is not Whitelisted");
            this.notAllowed = true;
          } else {
            const result = await this.contract.methods
              .mintNFTDuringPresale(noOfTokens, proof)
              .send({
                from: this.accountID,
                value: parseInt(this.nftPrice) * noOfTokens,
              })
              .on("receipt", function (res) {
                this.minted = true;
                this.isMinting = false;
                console.log("Receipt :", res);
              })
              .on("error", function (err) {
                this.isMinting = false;
                console.log("error:" + err);
                alert("Transaction Error");
                
              });
            this.minted = true;
            console.log("Test :", result);
          }
        }
      } else {
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (this.totalSupply >= this.currentSupply) {
          alert("Sold out!");
        } else {
          const result = await this.contract.methods
            .mintNFT(noOfTokens)
            .send({
              from: this.accountID,
              value: parseInt(this.nftPrice) * noOfTokens,
            })
            .on("receipt", function (res) {
              this.minted = true;
              this.isMinting = false;
              console.log("Receipt :", res);
            })
            .on("error", function (err) {
              this.isMinting = false;
              console.log(err);
              alert("Transaction Error");
            });
          this.minted = true;
          console.log("Test :", result);
        }
      }
      this.isMinting = false;
    },
  },
};
</script>
<style scoped>
.pricing-container {
  width: 80vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transform: translateX(100%);
  transition: transform 2.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.home-loaded .pricing-container {
  transform: translateX(0%);
  transition-delay: 1.2s;
}
.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading h1 {
  text-transform: uppercase;
  margin-top: 10px;
}
.heading h4 {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
}
.heading .metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading .metamask img {
  max-width: 40px;
  height: auto;
  margin-right: 10px;
}
.sale-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
  height: 30vh;
  border: 2px solid #000;
  padding: 2%;
}

.sale-card .need-border {
  border-bottom: 2px solid #000;
  padding: 2%;
}
.sale-card .qty {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.sale-card .qty .controller {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sale-card .qty img {
  width: 35px;
  height: auto;
  cursor: pointer;
}
.sale-card .qty p.unit {
  font-size: 1.5rem;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 900;
}
.sale-card .qty p.label {
  font-size: 1.5rem;
}
.sale-card .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sale-card .price p {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.sale-card .price .cost {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sale-card .price .cost span {
  font-size: 1.5rem;
}
.sale-card .price .cost img {
  width: 25px;
  height: auto;
}
.sale-card .remaining {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 2%;
}
.cta .button {
  margin: 0;
}
.cta .wallet-cta {
  margin-right: 2.5px;
}
.cta .mint-cta {
  margin-left: 2.5px;
}
/* MOBILE VIEW */
@media screen and (max-width: 768px) {
  .pricing-container {
    background: rgba(255, 255, 255, 0.842);
    width: 100vw;
    height: 100vh;
    padding: 60px 15px;
  }
  .sale-card {
    width: 100%;
    height: auto;
    padding: 10%;
  }
  .cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  .cta .button {
    padding: 10px 32px;
  }

  .cta .wallet-cta {
    margin-right: 0px;
    margin-bottom: 2.5px;
  }
  .cta .mint-cta {
    margin-left: 0px;
    margin-top: 2.5px;
  }
  .heading {
    margin-bottom: 0;
  }
  .heading h1 {
    text-align: center;
    font-size: 1.4rem;
    margin-top: 2px;
  }
  .heading h2 {
    font-size: 1.2rem;
  }
  .heading h4 {
    width: 100%;
    font-weight: 300;
    font-size: 0.8rem;
  }
  .heading img {
    width: 25px;
  }
  .sale-card .price p,
  .sale-card .price .cost span {
    font-size: 1.1rem;
    text-align: center;
  }
  .sale-card .qty p.label {
    font-size: 1.2rem;
  }
  .sale-card .qty img {
    width: 30px;
  }
  .sale-card .price .cost img {
    width: 22.5px;
  }
  .remaining p,
  .remaining span {
    font-size: 1.1rem;
  }
}
</style>
