<template>
  <div>
    <div class="mobile-logo">
      <a :href="mainSite">
        <img src="/logo.png" />
      </a>
    </div>
    <video id="mint-vid" playsinline="playsinline" autoplay="autoplay" muted="muted" class="home-vid">
      <source src="/logo-vid.mp4" type="video/mp4" />
    </video>
    <div class="container">
      <Charecters />
      <Pricing :totalNftCount="totalNftCount" :remainingNftCount="remainingNftCount" />
    </div>
  </div>
</template>

<script setup>
import Charecters from './Charecters.vue'
import Pricing from './Pricing.vue'
const mainSite = 'https://metavogue.netlify.app/'
var Web3 = require("web3");
var CryptoJS = require("crypto-js");
import abis from "../abis/Metavogue.json";
import address from "../address/address.json";
var MerkleTree = require("merkletreejs").MerkleTree;
var SHA256 = CryptoJS.SHA256;

const leaves = address.addresses.map((x) => x.replace("0x", "0x000000000000000000000000"));
const tree = new MerkleTree(leaves, SHA256, { sortPairs: true });
const root = tree.getRoot().toString("hex");

export default {
  name: "Mint",
  components: {
    Charecters,
    Pricing
  },
  data() {
    return {
      address: "",
      accountID: "",
      accountBalance: 0,
      abi: [],
      contract: [],
      wlClaimed: 0,
      // Contract
      isActive: false,
      isPresaleActive: false,
      currentSupply: 0,
      totalTokens: 3333,
      maxSupply: 3333,
      buyLimit: 2,
      nftPrice: 190000000000000000,
      whiteListMaxMint: 2,
      notAllowed: false,
      // Form data
      nftsCountToMint: 2,
      minted: false,
      isMinting: false,
      totalNftCount: 2000,
      remainingNftCount: 1000,
    };
  },
  mounted() {
    const homeVid = document.querySelector('#mint-vid')
    if (homeVid) {
      homeVid.addEventListener('click', () => (window.location.href = mainSite))
    }
    document.querySelector('body')?.addEventListener('touchstart', () => {
      const videos = document.querySelectorAll('video')
      if (videos && videos.length) {
        videos.forEach(vid => {
          if (!vid.playing) {
            vid.play()
          }
        })
      }
    })
  },
   async created() {
    await this.loadWeb3();
    this.GetRoot();
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    GetMerkleProof(walletAddress) {
      const leaf = walletAddress;
      return tree.getHexProof(leaf.replace("0x", "0x000000000000000000000000"));
    },
    GetRoot() {
      console.log("root");
      console.log(root);
      console.log(address.addresses.length);
      console.log("contract : " + this.address);
      return `0x${root}`;
    },
    async loadWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async (accounts) => {
          await this.setWallet(accounts[0]);
          this.wlClaimed = await this.contract.methods.whiteListClaimed(this.accountID).call();
        });
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        window.alert("Non-Ethereum browser detected. You should consider trying MetaMask !");
      }

      await this.loadContractData();
      setInterval(
        function () {
          this.loadContractData();
        }.bind(this),
        1000
      );
    },
    async pick(nftsCountToMint) {
      this.nftsCountToMint = nftsCountToMint;
      console.log(this.nftsCountToMint);
    },
    async loadContractData() {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();

      if (networkId !== abis.network) {
        window.alert("Please change to ethereum mainnet.");
        return;
      }

      this.abi = abis.abi;
      this.address = abis.address;
      this.contract = new web3.eth.Contract(this.abi, this.address);
      this.nftPrice = await this.contract.methods.NFTPrice().call();
      this.totalTokens = parseInt(await this.contract.methods.MAX_NFT_PUBLIC().call())+140;
      this.buyLimit = await this.contract.methods.BUY_LIMIT_PER_TX().call();
      this.isActive = await this.contract.methods.isActive().call();
      this.currentSupply = parseInt(await this.contract.methods.totalSupply().call())+140;
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
    },
    async setWallet(address) {
      this.accountID = address;
      this.accountBalance = await window.web3.eth.getBalance(this.accountID);
    },
    async connectWallet() {
      console.log("Connect to wallet");
      const web3js = window.web3;
      if (typeof web3js !== "undefined") {
        this.web3 = new Web3(web3js.currentProvider);
        const accounts = await window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .catch((err) => {
            alert(err.message);
          });
        await this.setWallet(accounts[0]);
        console.log("wlClaimed " + this.wlClaimed);
      } else {
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
        alert("Unable to connect to Metamask");
      }
    },
    //Minting Functionality
    async mint(e) {
      this.isMinting = true;
      e.preventDefault();
      this.accountBalance = await window.web3.eth.getBalance(this.accountID);
      if (this.accountID === "") {
        window.alert("Please connect wallet first!");
        this.isMinting = false;
        return;
      } else if (this.accountBalance <= this.nftPrice * this.nftsCountToMint) {
        this.isMinting = false;
        alert(`Insufficient funds`);
        return;
      }

      this.isActive = await this.contract.methods.isActive().call();
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
      console.log("isActive : ", this.isActive);
      console.log("isPresaleActive : ", this.isPresaleActive);

      if (!this.isActive) {
        this.isMinting = false;
        alert("Sale is not active yet!");
        return;
      }

      const noOfTokens = this.nftsCountToMint;
      console.log("nftPrice : ", this.nftPrice);
      if (this.isPresaleActive == true) {
        this.whiteListMaxMint = await this.contract.methods.WHITELIST_MAX_MINT().call();
        this.wlClaimed = parseInt(await this.contract.methods.whiteListClaimed(this.accountID).call());

        if (this.wlClaimed + this.nftsCountToMint > this.whiteListMaxMint) {
          alert(`Already minted ${this.wlClaimed} but max is ${this.whiteListMaxMint}`);
          this.isMinting = false;
          //this.notAllowed = true;
          return;
        }

        console.log("whiteListMaxMint : ", this.whiteListMaxMint);
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (noOfTokens > this.whiteListMaxMint) {
          alert("Buy limit for presale is : " + this.whiteListMaxMint);
          this.notAllowed = true;
        } else if (this.totalSupply >= this.totalTokens) {
          alert("Sold out!");
        } else {
          const proof = await this.GetMerkleProof(this.accountID);
          if (proof.length == 0) {
            //alert("This wallet is not Whitelisted");
            this.notAllowed = true;
          } else {
            const result = await this.contract.methods
              .mintNFTDuringPresale(noOfTokens, proof)
              .send({
                from: this.accountID,
                value: parseInt(this.nftPrice) * noOfTokens,
              })
              .on("receipt", function (res) {
                this.minted = true;
                this.isMinting = false;
                console.log("Receipt :", res);
              })
              .on("error", function (err) {
                this.isMinting = false;
                console.log("error:" + err);
                alert("Transaction Error");
                
              });
            this.minted = true;
            console.log("Test :", result);
          }
        }
      } else {
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (this.totalSupply >= this.currentSupply) {
          alert("Sold out!");
        } else {
          const result = await this.contract.methods
            .mintNFT(noOfTokens)
            .send({
              from: this.accountID,
              value: parseInt(this.nftPrice) * noOfTokens,
            })
            .on("receipt", function (res) {
              this.minted = true;
              this.isMinting = false;
              console.log("Receipt :", res);
            })
            .on("error", function (err) {
              this.isMinting = false;
              console.log(err);
              alert("Transaction Error");
            });
          this.minted = true;
          console.log("Test :", result);
        }
      }
      this.isMinting = false;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}
</style>
