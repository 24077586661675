<template>
  <div class="img-container">
    <img v-for="(char, index) in charecters" :src="`/charecters/${char}`" :key="index" :alt="char" class="mint-image" />
  </div>
</template>

<script setup>
const charecters = ['1.png', '2.png', '3.png', '4.png']

export default {
  name: "Raffle",
  data() {
    return {
      accountID: "",
      registered: false,
      charecters
    };
  },
  mounted() {
    let mintImages = document.querySelectorAll('.mint-image')
    let currShown = 1
    if (mintImages && mintImages.length) {
      setInterval(() => {
        mintImages.forEach(mi => mi.classList.remove('hidden'))
        mintImages.forEach(mi => mi.classList.remove('shown'))
        mintImages.forEach((mi, index) => {
          if (index === currShown) {
            mi.classList.add('shown')
          } else {
            mi.classList.add('hidden')
          }
        })
        currShown += 1
        if (currShown === mintImages.length) {
          currShown = 0
        }
      }, 3000)
    }
  }
};
</script>

<style scoped>
.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: transform 2.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.home-loaded .img-container {
  transform: translateY(0);
  transition-delay: 3s;
}
.img-container img {
  height: 100%;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 2s;
}
.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.shown {
  display: block;
  opacity: 1;
  visibility: visible;
}
/* MOBILE VIEW */
@media screen and (max-width: 768px) {
  .img-container {
    width: 0;
  }
  .img-container img {
    padding: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}
</style>
